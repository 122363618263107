import { SecondarySignInOptions } from '@sidebase/nuxt-auth/dist/runtime/types';

import { storeToRefs } from 'pinia';

import { setPageLoading } from '~/native/native.api';

export function useSignin() {
  const { signIn } = useAuth();

  const callbackUrlCookie = useCookie('redirect-url', {
    path: '/',
  });

  const generalStore = useGeneralStore();

  const { loading } = storeToRefs(generalStore);

  // const router = useRouter();

  const route = useRoute();

  async function handleSignin(
    signInOptions?: SecondarySignInOptions | undefined,

    paramsOptions?: Record<string, string> | undefined
  ) {
    try {
      loading.value = true;

      setPageLoading(true);

      callbackUrlCookie.value = signInOptions?.callbackUrl || '/';

      // TODO: Move this to a reusable place. This is a copy paste from the product page.

      const isIOS = navigator.userAgent.includes('iPhone');

      // This will either redirect you away, or open a new browser tab in the app.
      await signIn(isIOS ? 'groenhart-embedded' : 'groenhart', signInOptions, {
        ...paramsOptions,
        cancel_url: route.path,
      });
    } catch (error) {
      console.log(error);

      loading.value = false;

      setPageLoading(false);
    }
  }

  return {
    handleSignin,
  };
}
